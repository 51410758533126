<template>
  <img :src="image"/>
</template>

<script>
import image from "/public/calibration-pic.png";

export default {
  name: "Calibration",
  data: function () {
    return {
      image: image
    }
  }
}
</script>

<style scoped>

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  max-height: 100%;
  height: 66vh;
}

</style>